
import { mapGetters } from 'vuex'
export default {
  props: {
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      // region: 'user/region',
      detectedRegion: 'geolocation/region',
      userRegion: 'user/region',
    }),
    regionVar(){
      // REINSTATE THIS ONCE REGIONAL PAGES ARE READY
      // const regionVar = this.$props.region
      // const last2 = regionVar.slice(-2);
      // if (last2 === 'ie' || last2 === 'ca' || last2 === 'au' || last2 === 'za') {
      //   return '/'+last2
      // }
      // if(last2 === 'eu'){
      //   return '/ie'
      // }
      return ''
    }
  },
}
