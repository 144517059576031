
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      region: '',
      currency: '',
    }
  },
  computed: {
    ...mapGetters({
      currentLabel: 'cart/getPriceLabel',
      detectedRegion: 'geolocation/region',
      detectedCountry: 'geolocation/detectedCountry',
      userRegion: 'user/region',
      activeModal : 'pages/getActiveModal',
      regionClicked : 'cart/getRegionClicked'
    }),
    isDisabled(){
    	return this.currency === '' || this.region === ''
    },
    activeFlag(){
      const fl = this.$nuxt.$options.filters.flagFromLabel(this.currentLabel,this.detectedCountry)
      return fl
    },
    country(){
      let flag = this.detectedCountry.toUpperCase();
      const europeRegion = ['AL','AD','AM','AT','BY','BE','BA','BG','CH','CY','CZ','DE','DK','EE','ES','FO','FI','FR','GB','GE','GI','GR','HU','HR','IE','IS','IT','LI','LT','LU','LV','MC','MK','MT','NO','NL','PL','PT','RO','RS','RU','SE','SI','SK','SM','TR','UA','VA',]
      // const americaRegion = ["US","AU","CA"]
      // const ukRegion = ["GB"]
      // const asiaRegion = ["CN"]
      // const africaRegion = ["ZA"]
      if (flag === 'GB' || flag === 'AU' || flag === 'US' || flag === 'ZA' || flag === 'CA' || flag === 'IE' || europeRegion.includes(flag)){
        flag = 'globe'
      }
      return flag.toLowerCase()
    }
  },
  mounted() {
    // Set these to the current values we have in the store
    this.region = this.userRegion === 'default' ? this.detectedRegion : this.userRegion
    this.currency = this.currentLabel;
  },
  methods: {
    ...mapActions({
      updateRegion: 'user/updateRegion', // map `this.add()` to `this.$store.commit('increment')`
      changeCurrencyServer: 'cart/changeCurrencyServer',
      updateActiveModal: 'pages/updateActiveModal',
    }),
    updateModal(id){
      this.updateActiveModal(id)
      console.log('Active Modal: ',this.activeModal)
    },
    hide() {
      this.updateActiveModal(null)
    },
    setPriceLabel() {
      this.updateRegion(this.region)
      this.changeCurrencyServer(this.currency)
      // this.showModal = false
      this.hide()
    },
    setRegion(region) {
      // this.currency = currency
      if( region === 'TEFLORG'){
        this.region = 'en'
      } else if( region === 'IRELAND'){
        this.region = 'ie'
      } else if( region === 'CANADA'){
        this.region = 'ca'
      } else if( region === 'AUSTRALIA'){
        this.region = 'au'
      } else if( region === 'SOUTHAFRICA'){
        this.region = 'za'
      } else if( region === 'EU'){
        this.region = 'ie'
      } else {
        this.region = 'en-us'
      }
      this.updateRegion(this.region)
      this.changeCurrencyServer(region)
      // this.showModal = false
      this.hide()
    },
  },
}
